<template>
    <div class="page-content w-100">
        <div class="navbarmobileFix">
            <div class="row g-0">
      <div class="col-sm-12">
        <p class="text-center page-heading">Communities and Groups</p>
      </div>
    </div>
    <div class="d-block d-lg-none">
    <div class="row">
      <div class=" col-10 col-sm-9 col-lg-10 mb-0 ">
        <p class="postPatientGroup" style="margin-left: 20px;">Patient support groups</p>
      </div>
      <div class="col-2 col-sm-3 col-lg-2 mb-0">
        <p class="closeButton " @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
    </div>
    <div class="hr-border"></div>
  </div>
        <div>
            <section >
                <div class=" text-left text-align">
                    <div class="headingPatientGroup">
                    <!-- FOR DESKTOP  --> 
                    <div class="choosePatientGroupText subPatientGroupTop d-none d-lg-block">
                        <h4 class="subPatientGroupTop ">Select a disorder type to join the relevant patient groups</h4>
                    </div>
                    <!-- FOR MOBILE  -->
                    <div class="choosePatientGroupText subPatientGroupTop d-block d-lg-none">
                        <h2 class="subPatientGroupTop ">Select a disorder type to view the related patient groups</h2>                        
                    </div>
                    <div class="mt-4">
                        <select class="form-control JoinPatient" name="select" id="select" v-model="selected" @click="getPatintGroup($event)">
                            <option value="1">Select disorder</option>
                            <option v-for="index in allTopics" :key="index">{{ index.name }}</option>
                        </select>
                    </div>
                </div>
                    <div v-if="filteredSpecilities.length">
                    <div class="row box-items-space" >
                        <div class="col-opt-box" v-for="index in filteredSpecilities" :key="index" :disabled="isGroupInPublicData(index)" :class="[isGroupInPublicData(index) ? 'eventNone' : '']" @click="updatePatientGroup(index)" >
                            <div class="col-fix mt-3 color-bg" >
                                <div class="btn-text-bg">
                                    <div class="box-text-bg">
                                        <div class="eyes-text">
                                            <p>{{ index.group_name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button :class="[isGroupInPublicData(index) ? 'disableButton' : '']" class="btn btn-action-background"  >{{ isGroupInPublicData(index) ? 'Joined' : 'Join group' }}</button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div v-else-if="selected !=='1' && !filteredSpecilities.length" class="mt-5 text-align text-center">
                <h4 style="margin-top: 5px; margin-left: 10px;color:rgb(239, 63, 63)">No groups currently available</h4>
            </div>
                </div>
            </section>
        </div>
    </div>
    </div>
</template>
<script>
import axios from "axios"
import { defineComponent } from "vue";
export default defineComponent({
    components: {
    },
    el: '#hide',
    data() {
        return {
            allTopics: [],
            selected:'1',
            objSpeclities:{},
            filteredSpecilities:[],
            publicData:{},
            publicPatientGroupsData:[]
        }
    },
    computed: {
    isGroupInPublicData() {
        return (group) => {
            return this.publicData.publicPatientId.some(item => item.id === group.id);
        };
    },
},
    
    created: async function () {
        let publicUserId = localStorage.getItem('publicSignUpData')
        this.publicId = JSON.parse(publicUserId);
        this.getAllTopics();
       await this.getPublic();
    },
    methods: {
        GoSetting() {
      this.$router.push({ path: `/user/community-groups` });
    },
         getPublic() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
          axios.get(`${BASE_API_URL}/public-user/${this.publicId}/one`)
                .then((response) => {
                  this.publicData=response.data
                })
        },
        updatePatientGroup(data){
                this.publicPatientGroupsData.push(data)
                this.publicPatientGroupsData = this.publicPatientGroupsData.concat(this.publicData.publicPatientId)
                const signInPayload ={
                  publicPatientId: this.publicPatientGroupsData,
                }
                const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
               axios
                .put(`${BASE_API_URL}/public-user/${this.publicData.id}/update`, signInPayload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response?.data) {
                        this.getPublic();
                    }})
        },
        async getAllTopics() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  
        const response = await axios.get(`${BASE_API_URL}/topics`);

        if (response?.data) {
            // Filter out the object with id: 24
            this.allTopics = response.data.filter(topic => topic.id !== 24);
        }
        },
        getPatintGroup(event){
            this.filteredSpecilities=[];
        const topics= this.allTopics.filter(item => item.name == event.target.value);
        topics.map((item)=>{
        this.objSpeclities={
            check :item.check,
            id:item.id,
            name:item.name
        }
        })
        if(event.target.value!= 1){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    axios
    .get(`${BASE_API_URL}/patientgroup/specialities?disorder_id=${this.objSpeclities.id}`)
    .then((response) => {
        this.filteredSpecilities=[];
        if (response?.data) {
                response.data.map((patientGroup)=>{
            if(patientGroup.status == true){
                this.filteredSpecilities.push(patientGroup)
            }
        })
        }})
    }else if(event.target.value == 1){
                this.objSpeclities =false
        this.filteredSpecilities=[];
    }
},
    }
})

</script>
<style>
.disableButton{
    background-color: #cccccc !important;
    color: #000 !important;
}
.eventNone{
    pointer-events: none !important;
    cursor: auto !important;
}
.headingPatientGroup{
    background-color: #fff;
    border-radius: 30px;
    padding:  25px;
    border: 2px solid #c9c9c9;
    margin-top: 1rem;
}
select.form-control.JoinPatient {
    width: 400px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    display: inline;
    margin-bottom: 5px;

}
.eyes-text p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-text-bg {
    font-size: 45px;
    color: #fff;
}

.row.box-items-space {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 25% 25% 25%;
    justify-content: center;
    grid-gap: 20px;
}

.col-opt-box {
    width: 100%;
    display: grid;
    justify-content: center;
   cursor: pointer;
}

.col-fix.mt-3.color-bg {
    border: 2px solid #00979e;
    width: 235px;
    /* margin-bottom: 30px; */
    background-color: #00979e;
}
.btn-text-bg {
    text-align: center;
    padding: 15px;
    color: #fff;
    background-color: #00979e;
}
button.btn.btn-action-background {
    background: #fff;
    color: #00979e;
    width: 100%;
    border-radius: inherit;
    border: 2px solid #00979e;
}
.choosePatientGroupText h2 {
    color: #000;
    font-size: 20px;
}
.navbar-light .navbar-nav .nav-link {
    color: #00979e;
}
@media screen and (max-width:1490px) {
    .row.box-items-space {
        grid-template-columns: repeat(2, 1fr);
    }

}
@media screen and (max-width:992px) {
    .navbarmobileFix {
        margin-bottom: 4rem;
    }
    .headingPatientGroup{
    border-radius: 0px;
    padding:  0px;
    border:none;
    margin-top: 0rem;
    padding-left: 20px;
    }
    .postPatientGroup {
    margin-left: 20px;
    margin-top: 1rem;
    color: #000;
    font-weight: 550;
    font-size: 21px;
  }
    select.form-control.JoinPatient {
        width: 300px;   
    }

    .col-fix.mt-3.color-bg {
        width: 210px;
    }

    .row.box-items-space {
        grid-template-columns: 30% 30% 30%;
        grid-gap: 30px;
    }
}
@media screen and (max-width:991px) {
    .subPatientGroupTop {
        margin-top: 2rem;
    }
}
@media screen and (max-width:768px) {
    select.form-control.JoinPatient {
        width: 300px;
    }

    .row.box-items-space {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
}
@media screen and (max-width:576px) {
    select.form-control.JoinPatient {
        width: 250px;
    }
    .subPatientGroupTop {
        margin-top: 1.5rem;
    }
}
@media screen and (max-width:550px) {

    .row.box-items-space {
        margin-top: 10px;
    }
}
@media screen and (max-width:470px) {
    .row.box-items-space {
        grid-gap: 0%
    }
    .col-fix.mt-3.color-bg {
        width: 133px;
    }
    .box-text-bg {
        font-size: 26px;
    }
    .btn-text-bg {
        padding: 4px;
    }
    .eyes-text p {
        font-size: 16px;
    }
}
</style>